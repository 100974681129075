import React, { useContext, useState, useEffect } from "react";
import { Input } from "components/bootstrap";

import FormWrap from "./common/FormWrap";
import FormAdvancedOptions from "./FormAdvancedOptions";
import { FormDataContext } from "./context/FormData";
import {
  ErrorMessageType,
  FormDataContextType,
  HandleFieldUpdateType,
  HandleSubmitType,
} from "./types";

interface Props {
  onSubmit: HandleSubmitType;
  onChange: HandleFieldUpdateType;
}

const StepSubscribe: React.FC<Props> = ({ onSubmit, onChange }) => {
  const { formData } = useContext<FormDataContextType>(FormDataContext);
  const [formError, setFormError] = useState<ErrorMessageType>(null);
  const [azureBatchSizeError, setAzureBatchSizeError] = useState<boolean>(false);
  const [azureWaitTimeError, setAzureWaitTimeError] = useState<boolean>(false);
  const { pollingInterval,azureBatchSize,azureWaitTime } = formData;

  const handleSubmit = () => {
    let error = false;
    if (pollingInterval.value <1){
      setFormError({
        full_message: "Please provide valid polling interval",
        nice_message:
          "Minimum allowable polling interval is 1 minute.",
      });
      error = true;
    }
    if (azureBatchSize.value <1 || azureBatchSize.value >1000){
    error = true;
    setAzureBatchSizeError(true);

    }
    if (azureWaitTime.value <1 || azureWaitTime.value >30)
    {
     error = true;
     setAzureWaitTimeError(true);
    }
    if (!error){
    setFormError(null);
    onSubmit();
    }
  };

  return (
    <FormWrap
      onSubmit={handleSubmit}
      buttonContent="Proceed"
      title=""
      error={formError}
      description=""
    >
      <Input
        id="pollingInterval"
        name="pollingInterval"
        type="number"
        value={pollingInterval.value || pollingInterval.defaultValue || ''}
        min="1"
        max="60"
        step="1"
        onChange={onChange}
        required
        help="Determines how often (in minutes) Graylog will check for new data in Azure. The smallest allowable interval is 1 minute."
        label="Polling Interval"
      />

      <FormAdvancedOptions onChange={onChange} azureBatchSizeError={azureBatchSizeError} azureWaitTimeError={azureWaitTimeError} />
    </FormWrap>
  );
};

export default StepSubscribe;
